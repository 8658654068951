document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll("a[href^='#'].anchor").forEach(function (elm) {
    const href = elm.getAttribute('href')
    if (href === '#') return

    elm.addEventListener('click', function (event) {
      const target = document.querySelector(href)
      if(target === null) return
      const body = document.querySelector('#c-body')
      body.scrollTop = target.offsetTop
      event.preventDefault();// elmがリンクだった場合、hrefに飛ばなくなる
      event.stopPropagation();// 親のdivとかがある場合に親のdivのclickイベントが発生しなくなる。この行を削除するとclickedが複数出るのがわかる
    });
  })
})
