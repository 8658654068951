require("@rails/ujs").start()
require("@rails/activestorage").start()

// PromiseなどをIE11で使えるようにする
import "core-js/stable"
import "regenerator-runtime/runtime"

// core ui
require("@coreui/coreui/dist/js/coreui")
import "@coreui/coreui/scss/coreui.scss"

// core ui icon
require.context('@coreui/icons/sprites', true)
import "@coreui/icons/css/all.css"

// images
require.context("@image", true)

// core ui iconのsvgのie11対応
require("svgxuse")

// vuejsでカバーできないinput内でのenter submitを防ぐ
// vuejsの入っている箇所はdisabled_enter_submitが効かないため、タグ自体に対策を施す
require("@packs/modules/disabled_enter_submit")

// スムーズスクロール
require("@packs/modules/anchor_link")

// action text
require("@packs/modules/trix")
// require("trix")
require("@rails/actiontext")

// style
import "@style/application.scss"
